import cn from "classnames";
import styles from "./Header.module.sass";
import { Image } from "@storybook";

export const Header = () => {
  return (
    <>
      <div className={styles.tablet_wrap}>
        <header className={cn(styles.header)}>
          <div className={cn("excahngecontainer", styles.container)}>
            <div className={styles.logo}>
              <div className={styles.logo1}>
                <Image
                  className={styles.brand_logo}
                  fileName={`images/Liquidity_Blue_Logo.png`}
                />
              </div>
            </div>
            <div
              className={styles.wrapper}
              // style={{ width: "100%" }}
            >
              <div className={styles.containSettings}>
                {/* <div className={styles.userDropdown}> */}
                {/* <ChangeUser /> */}
                {/* <Settings className={styles.settings} /> */}
                {/* <Settings className={styles.settings} />
          <div className={styles.control}>
            <NavLink
              className={styles.activity}
              // activeClassName={styles.active}
              to="/activity"
            >
                              <Notifications className={styles.notifications} />
                            </NavLink>
*/}
              </div>
            </div>
            {/* <div className={styles.btns}>
            <Link
              className={cn("button-small", styles.button)}
              activeClassName={styles.active}
              to="/sign-up"
              onClick={() => setVisibleNav(false)}
            >
              Sign Up
            </Link>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              activeClassName={styles.active}
              to="/sign-in"
              onClick={() => setVisibleNav(false)}
            >
              Login
            </Link>
          </div> */}
            {/* <button
              className={cn(styles.burger, { [styles.active]: visibleNav })}
              onClick={() => setVisibleNav(!visibleNav)}
            ></button> */}
          </div>
          {/* </div> */}
        </header>
      </div>
    </>
  );
};
