import { Route, Routes } from "react-router-dom";
// import { GroupBulkState } from "states";
import { Header, Operation } from "views";

export const AllRoutes = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="*" element={<Operation />} />
      </Routes>
    </>
  );
};
