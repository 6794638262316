import { DateTime } from "luxon";

export const getDate = (date: string | number | Date, format?: string) => {
  switch (typeof date) {
    case "number":
      return DateTime.fromMillis(date).toFormat(format ?? "MMM dd, yyyy");
    case "string":
      return DateTime.fromISO(date).toFormat(format ?? "MMM dd, yyyy");
    default:
      const formatToString = new Date(date).toISOString();
      return DateTime.fromISO(formatToString).toFormat(format ?? "yyyy-MM-dd");
  }
};

export const getDateWithTime = (
  date: string | number | Date,
  format?: string
) => {
  switch (typeof date) {
    case "number":
      return DateTime.fromMillis(date).toFormat(
        format ?? "MMM dd, yyyy HH:mm:ss"
      );
    case "string":
      return DateTime.fromISO(date).toFormat(format ?? "MMM dd, yyyy  HH:mm:ss");
    default:
      const formatToString = new Date(date).toISOString();
      return DateTime.fromISO(formatToString).toFormat(format ?? "yyyy-MM-dd");
  }
};

export const getTime = (date: string | number | Date, format?: string) => {
  switch (typeof date) {
    case "number":
      return DateTime.fromMillis(date).toFormat("hh:mm a");
    case "string":
      return DateTime.fromISO(date).toFormat("hh:mm a");
    default:
      const formatToString = new Date(date).toISOString();
      return DateTime.fromISO(formatToString).toFormat(format ?? "yyyy-MM-dd");
  }
};

export const getDifferenceInDate = (date1: any, date2: any) => {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
