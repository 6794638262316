import { AllRoutes } from './views';

import './app.scss';

const App = () => {
  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
};

export default App;
