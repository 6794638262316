import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  addYears,
  startOfYear,
  endOfYear,
} from "date-fns";
import { DateTime } from "luxon";

const endDate = DateTime.now();
const startDate = endDate.minus({ months: 1 });

export const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),

  startOfLastWeek: startOfDay(addDays(new Date(), -6)),
  endOfLastWeek: endOfDay(new Date()),

  startOfLast30days: startDate.toJSDate(),
  endOfLast30days: endDate.toJSDate(),

  startOfThisMonth: startOfMonth(addMonths(new Date(), 0)),
  endOfThisMonth: new Date(),

  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

  startOfLastQuarter: startOfMonth(addMonths(new Date(), -3)),
  endOfLastQuarter: endOfMonth(addMonths(new Date(), -1)),

  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),

  startOfYear: startOfYear(new Date()),
  tillDate: endOfDay(new Date()),
};
