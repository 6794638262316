import { ReactNode, FC, KeyboardEvent } from "react";

import classNames from "classnames";
import ReactModal, { Styles } from "react-modal";

type Props = {
  className?: string;
  isOpen: boolean;
  closeModal?: () => void;
  overlayStyles?: Styles;
  modalName: string;
  children: ReactNode;
  showCloseBtn?: boolean;
  title?: string | JSX.Element;
  isStopOutsideClick?: boolean;
  closeOnEscBtn?: boolean;
  ariaHideApp?: any;
};

export const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(44, 48, 57, 0.8)",
    zIndex: 100,
    overflowY: "auto",
  },
};

export const Modal: FC<Props> = ({
  className,
  isOpen,
  closeModal = () => ({}),
  overlayStyles,
  modalName,
  children,
  showCloseBtn = true,
  title,
  isStopOutsideClick = true,
  closeOnEscBtn = true,
}) => {
  const classes = classNames("Modal", className);

  const handleClose = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      closeModal();
    }
  };

  const combinedStyles = {
    ...styles,
    overlay: { ...styles.overlay, ...overlayStyles },
  };

  return (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel={modalName}
      style={combinedStyles as Styles}
      className={classes}
      shouldCloseOnOverlayClick={isStopOutsideClick}
      shouldCloseOnEsc={closeOnEscBtn}
      parentSelector={() => document.querySelector("body") as any}
      ariaHideApp={false}
    >
      <div className="Modal__wrapper">
        <div>{title}</div>
        {showCloseBtn && (
          <div
            className="Modal__closeButton"
            onClick={closeModal}
            onKeyPress={handleClose}
            role="button"
            tabIndex={0}
          >
            <i className="ri-close-line Modal__closeIcon" />
          </div>
        )}
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
