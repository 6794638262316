// import { ISearchedData } from "./types";

import { ChangeEvent, useCallback, useMemo, useState } from "react";
// import useDarkMode from "use-dark-mode";

// import { Icon, SkeletonSearch } from "components";
// import { APIS } from "constant";
// import { useNetwork } from "hooks";
// import { debounce } from "utils";
// import { SearchedRow } from "./componets/searchRow";
// import { Image } from "@storybook";

import styles from "./search.module.sass";
import { useRecoilState } from "recoil";
import { operationInputState } from "states";

type Props = {
  placeholder?: string,
}

export const Search  = (props: Props) => {
  const {placeholder = "Search"} = props;
  const [searchedData] = useState<any[]>([]);
  const [globalSearch, setGlobalSearch] = useRecoilState(operationInputState);
  const [isLoadingData, setLoadingData] = useState(false);

  // const {
  //   get: searchGlobal,
  //   data: searchGlobalData,
  //   // loading: searching,
  //   isLoaded: isSearched,
  // } = useNetwork();

  // const darkMode = useDarkMode();

  // useEffect(() => {
  //   if (searchGlobalData && searchGlobalData.data) {
  //     setSearchedData(searchGlobalData.data);
  //   }
  // }, [searchGlobalData]);

  // useEffect(() => {
  //   if (!searching && isSearched) {
  //     setLoadingData(false);
  //   } else {
  //     setLoadingData(true);
  //   }
  // }, [isSearched, searching]);

  // const handleSearch = useCallback((value: string) => {
  //   if (value.trim().length !== 0) {
  //     setLoadingData(true);
  //     searchGlobal(`${APIS.Search}${value.trim()}`).then((res) => {
  //       if (res?.data) setLoadingData(false);
  //     });
  //   } else {
  //     setSearchedData([]);
  //     setLoadingData(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleClickClose = useCallback((value: string) => {
    if (value === "close") {
      setGlobalSearch("");
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounceSearch = useCallback(debounce(handleSearch, 800), []);

  const renderForm = useMemo(() => {
    return (
      <div className={styles.form}>
        <input
          className={styles.input}
          type="text"
          name="Global"
          placeholder={placeholder}
          value={globalSearch}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLoadingData(true);
            setGlobalSearch(e.target.value);
            // setGlobalSearch(e.target.value);
            // debounceSearch(e.target.value);
          }}
        />
        <button
          onClick={() => handleClickClose(globalSearch && "close")}
          className={`${styles.result} ${globalSearch && styles.closeBtn}`}
        >
          <i className="ri-search-line"></i>

          {/* <Icon name={globalSearch ? "close" : "search"} size="20" /> */}
        </button>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSearch]);

  // const renderSearchedData = useMemo(() => {
  //   if (searchedData) {
  //     return searchedData.map(
  //       ({ symbol, name, type, image, id, imageFrom, imageTo }, index) => (
  //         <SearchedRow
  //           symbol={symbol}
  //           name={name}
  //           type={type}
  //           index={index}
  //           image={image}
  //           id={id}
  //           imageFrom={imageFrom}
  //           imageTo={imageTo}
  //         />
  //       )
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchedData]);

  return (
    <div className={styles.currencySearch}>
      {renderForm}
      {globalSearch &&
        (isLoadingData ? (
          <div className={styles.loader}>
            {/* <SkeletonSearch listsToRender={6} /> */}
          </div>
        ) : // isSearched &&
        searchedData.length !== 0 ? (
          <div className={styles.searchData}>{/* {renderSearchedData} */}</div>
        ) : (
          <div className={styles.noData}>
            <div className={styles.noDataContent}>
              {/* <Image
                  fileName={`images/${
                    darkMode.value ? "no_data_dark.svg" : "no_data_light.svg"
                  }`}
                /> */}

              <div>No Result for "{globalSearch}"</div>
            </div>
          </div>
        ))}
    </div>
  );
};
