import { REACT_APP_HOST_URL } from "envs";

interface IImage {
  fileName?: string;
  url?: string;
  className?: string;
  height?: string;
  width?: string;
  props?: any;
}

export const Image = ({
  fileName,
  url: imageUrl,
  className,
  height,
  width,
  props,
}: IImage) => {
  const url =
    imageUrl ??
    `${
      REACT_APP_HOST_URL ? REACT_APP_HOST_URL : window.location.origin
    }/media/${fileName}`;
  return (
    <img
      src={url}
      alt={fileName}
      className={className ?? ""}
      height={height}
      width={width}
      {...props}
    />
  );
};
